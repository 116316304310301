import { FC, Fragment } from 'react';
import clsx from 'clsx';
import { Button, Link, Types, baseVariables } from '@marriott/mi-ui-library';
import { PropertyCardProps, PropertyCardType } from './PropertyCard.types';
import { StyledPropertyCard, StyledPropertyDescription } from './PropertyCard.styles';
import { ImageCarousel } from '../ImageCarousel';
import { ViewType } from '../../organisms/SearchResults/SearchResults.types';
import { BrandLogo as Brand } from '../../admin/molecules/BrandLogo';
import { useMediaQuery } from '../../hooks';
import { formatAmount, moveDecimalPoint } from '../../utils';
import { Amount } from '@marriott/mi-groups-graphql';
import { CHINESE_LANG } from '../../constants';
import { useLocaleStore } from '../../store';

export const PropertyCard: FC<PropertyCardProps> = ({
  id,
  type = PropertyCardType.REGULAR,
  media,
  brand,
  badge,
  mediaLabels,
  propertyName,
  reviewsAndDistance,
  description,
  showDescription = true,
  viewDetailsCta,
  price,
  footer,
}) => {
  const { rating, reviewCount, reviewLink, reviewTooltipText, distance } = reviewsAndDistance;
  const pricingInfo = footer?.pricingInfo;
  const { labels: pricingInfoLabels, data: pricingInfoData } = pricingInfo || {};

  const isTablet = useMediaQuery(baseVariables.mediaQuery.md);
  const isDesktop = useMediaQuery(baseVariables.mediaQuery.lg);

  const { locale } = useLocaleStore();

  const isCompactCard = type === PropertyCardType.COMPACT;

  const showPricingInfo = !!(pricingInfo && (pricingInfoData?.roomRate?.value || pricingInfoData?.spaceRate?.value));

  const brandImage = brand?.photos?.length && brand.photos[0]?.content?.length ? brand.photos[0].content[0] : undefined;

  const propertyTitle = (
    <h2
      className={clsx(
        isCompactCard ? 't-subtitle-m' : isDesktop ? 't-subtitle-xl' : isTablet ? 't-subtitle-l' : 't-subtitle-m'
      )}
    >
      {propertyName.title}
    </h2>
  );

  const propertyRating = (
    <>
      <span
        className={clsx(
          'm-ratings-stars icon-alt icon-star-fill',
          isTablet && !isCompactCard ? 't-font-s' : 't-font-xs'
        )}
      ></span>
      <span className={clsx('mr-1', isTablet && !isCompactCard ? 't-font-s' : 't-font-xs')}>{rating}</span>
    </>
  );

  const ratingReviewsAndMiles = (
    <div className="d-flex reviews-distance">
      {reviewCount ? (
        locale === CHINESE_LANG ? (
          <div
            className="m-ratings m-tooltip-top rating-reviews"
            data-content={reviewTooltipText}
            data-testid="rating-tooltip"
          >
            {propertyRating}
          </div>
        ) : (
          <Link linkClassName="m-ratings rating-reviews" linkHref={reviewLink}>
            {propertyRating}
            <span className={clsx(isTablet && !isCompactCard ? 't-font-s' : 't-font-xs')} data-testid="review-count">
              ({reviewCount})
            </span>
          </Link>
        )
      ) : null}
      {reviewCount && distance ? (
        <span className={clsx('mx-2', isTablet && !isCompactCard ? 't-font-s' : 't-font-xs')}>|</span>
      ) : null}
      {distance ? (
        <div className={clsx('m-icon-text', isTablet && !isCompactCard ? 't-font-s' : 't-font-xs')}>
          <span className="icon-xs icon-location icon-xs mr-1"></span>
          <span>{distance}</span>
        </div>
      ) : null}
    </div>
  );

  const renderPricingInfo = ({ rateFrom, amount, time }: { rateFrom: string; amount: Amount; time: string }) => {
    return (
      <div>
        <div className="t-font-xs label">{rateFrom}</div>
        <span className="t-subtitle-xl price">
          {`${formatAmount(+moveDecimalPoint(amount.value, amount.valueDecimalPoint), 0)} `}
        </span>
        <span className="t-font-xs label">
          {amount.currency}
          {time}
        </span>
      </div>
    );
  };

  return (
    <StyledPropertyCard
      data-component-name="m-groups-PropertyCard"
      data-testid="groups-PropertyCard"
      className={clsx('property-card', isCompactCard && 'compact-card', `property-${id}`)}
      showPricingInfo={showPricingInfo}
    >
      <div data-testid="property-media" className="property-media">
        <ImageCarousel
          media={media}
          brandImage={brandImage}
          labels={{
            carouselAriaLabel: mediaLabels?.carouselAriaLabel.replace('{name}', propertyName.title),
            imageAriaLabel: mediaLabels?.carouselImageAriaLabel,
          }}
          imageLoading="lazy"
          containerClass={isCompactCard ? 'compact-carousel' : 'regular-carousel'}
          viewType={isCompactCard ? ViewType.MAP_VIEW : ViewType.LIST_VIEW}
          addScrimToImage
        />
        <div className="brand-logo-container">
          <Brand
            brandCode={brand?.id.toUpperCase()}
            ariaLabel={mediaLabels?.logoAriaLabel.replace('{name}', brand?.name)}
            tabIndex={0}
          />
        </div>
        {badge ? (
          <div className="media-badge">
            <p className="t-label-alt-xs m-0">{badge}</p>
          </div>
        ) : null}
      </div>
      <div data-testid="property-info" className={clsx('property-info', !footer && 'without-footer')}>
        {propertyName.onClick ? (
          <Button className="property-name p-0" callback={propertyName.onClick}>
            {propertyTitle}
          </Button>
        ) : (
          propertyTitle
        )}
        {ratingReviewsAndMiles}
        {showDescription ? (
          typeof description === 'string' ? (
            <p className="t-font-xs mt-3">{description}</p>
          ) : (
            <StyledPropertyDescription columnCount={isCompactCard ? 1 : 2}>
              {description?.map((item, index) => (
                <Fragment key={index}>
                  <span className={clsx(item.value ? 't-font-xs' : 't-label-xs attribute')}>
                    {item.label}
                    {item.value ? ':' : null}
                  </span>
                  {item.value ? <span className="t-label-xs text-left">{item.value}</span> : null}
                </Fragment>
              ))}
            </StyledPropertyDescription>
          )
        ) : null}
        {viewDetailsCta && (
          <Button
            type={Types.ButtonTypeVariation.Button}
            buttonCopy={viewDetailsCta.label}
            className="m-link-tertiary-button view-details"
            custom_click_track_value={viewDetailsCta?.trackDescription}
            callback={viewDetailsCta.onClick}
          ></Button>
        )}
        {price?.amount ? (
          <div className="d-flex justify-content-end align-items-end mb-auto">
            <span className="m-price">{formatAmount(price.amount)}</span>
            <span className="t-label-xs ml-1">{price.currencyLabel}</span>
          </div>
        ) : null}
        {footer && (
          <div
            className={clsx('d-flex align-items-center property-footer', !footer.secondaryCta && 'justify-content-end')}
          >
            {showPricingInfo && (
              <div className="d-flex mr-3 pricing-info">
                {pricingInfoData?.roomRate?.value
                  ? renderPricingInfo({
                      rateFrom: pricingInfoLabels?.guestRoomsFrom as string,
                      amount: pricingInfoData?.roomRate,
                      time: pricingInfoLabels?.perNight as string,
                    })
                  : null}
                {pricingInfoData?.spaceRate?.value
                  ? renderPricingInfo({
                      rateFrom: pricingInfoLabels?.eventSpacesFrom as string,
                      amount: pricingInfoData?.spaceRate,
                      time: pricingInfoLabels?.perDay as string,
                    })
                  : null}
              </div>
            )}
            {footer.secondaryCta && (
              <Button
                type={Types.ButtonTypeVariation.Button}
                buttonCopy={footer.secondaryCta.label}
                className="m-button-s m-button-secondary text-center property-cta"
                custom_click_track_value={`${footer.secondaryCta.trackLocation} | ${footer.secondaryCta.trackDescription} |internal`}
                callback={footer.secondaryCta.onClick}
              />
            )}
            {footer.primaryCta && (
              <Button
                type={Types.ButtonTypeVariation.Button}
                buttonCopy={footer.primaryCta.label}
                className={clsx(
                  'm-button-s m-button-primary ml-2 text-center property-cta',
                  footer.primaryCta.isSelected && 'm-button-secondary'
                )}
                custom_click_track_value={`${footer.primaryCta.trackLocation} | ${footer.primaryCta.label} |internal`}
                callback={footer.primaryCta.onClick}
              />
            )}
          </div>
        )}
      </div>
    </StyledPropertyCard>
  );
};
