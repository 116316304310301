import { useEffect } from 'react';
import { TAB_KEY } from '../constants';

export function useFocusOut({
  ref,
  onFocusOut,
  handleClick = true,
  handleKeyup = false,
}: {
  ref: HTMLElement | Array<HTMLElement | null> | null;
  onFocusOut: (isFocusOut: boolean) => void;
  handleClick?: boolean;
  handleKeyup?: boolean;
}) {
  useEffect(() => {
    function clickListener(event: MouseEvent) {
      const refs = ref ? (Array.isArray(ref) ? ref : [ref]) : [];
      if (refs.length && handleClick) {
        if (refs.some(elementRef => elementRef?.contains(event.target as Node))) {
          onFocusOut(false);
        } else {
          onFocusOut(true);
        }
      }
    }

    function keyupListener(event: KeyboardEvent) {
      const refs = Array.isArray(ref) ? ref : [ref];
      if (refs.length && handleKeyup && event.key === TAB_KEY) {
        if (refs.some(elementRef => elementRef?.contains(event.target as Node))) {
          onFocusOut(false);
        } else {
          onFocusOut(true);
        }
      }
    }

    document.addEventListener('click', clickListener);
    document.addEventListener('keyup', keyupListener);

    return () => {
      document.removeEventListener('click', clickListener);
      document.removeEventListener('keyup', keyupListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
}
