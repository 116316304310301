import { ResponseStatus } from '../roominglist/responseStatus.types';
import { Property, HotelMediaContent, Brand } from '../search/searchPropertiesByGeolocation.types';
import { PropertyBasicInformation } from '../search/searchProperty.types';
import { MiniHotel, RoomTypes } from './addHotel.types';

export type ReslinkEventByIdInput = {
  input: {
    eventId: string;
    reslinkType: ReslinkType;
  };
  imageLimitPerCategory?: number;
};

export enum ReslinkType {
  GRP = 'RESLINK_GROUP',
  CORP = 'RESLINK_CORPORATE',
  NONE = '',
}

export type ReslinkEventByIdResponse = ReslinkEvent & Partial<ResponseStatus>;

export type ReslinkEventByIdForActiveEventResponse = EventFlags & Partial<ResponseStatus>;

type ReslinkEvent = EventFlags & {
  id?: string;
  resLinkType?: ReslinkType;
  localeURL?: string;
  associatedUsers?: string[];
  eventName: string;
  customImageURLs: ImageType[];
  eventDescription: string;
  eventCustomLinks: EventCustomLink[];
  startDate: string;
  endDate: string;
  hotelDetails: HotelDetails[];
};

export type ImageType = {
  name?: string;
  link: string;
};

export type EventCustomLink = {
  name: string;
  link: string;
};

export type EventFlags = {
  isActive?: boolean;
  isAssociatedEvent?: boolean;
};

export type HotelDetails = {
  groupCodes: string[];
  corporateCode: string;
  miniHotelId: string;
  miniHotelStartDate: string;
  overriddenStartDate: string;
  overriddenEndDate: string;
  maxOccupancy: string;
  property: Omit<Property, 'meetingRooms' | 'basicInformation'> & {
    seoNickname: string;
    media: HotelMediaContent;
    basicInformation: PropertyBasicInformation & { brand: Brand };
    roomTypes: RoomTypes;
  };
  miniHotelInfo: {
    node: MiniHotel;
  }[];
};

export type ReslinkEventForEmailResponse = ReslinkEventForEmail & Partial<ResponseStatus>;

export type ReslinkEventForEmail = {
  id: string;
  eventName: string;
  startDate: string;
  endDate: string;
  hotelDetails: HotelDetailsForEmail[];
};

export type HotelDetailsForEmail = {
  property: {
    basicInformation: {
      name: string;
    };
  };
  groupCodes: string[];
  miniHotelInfo?: {
    node: MiniHotel;
  }[];
};
