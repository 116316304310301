import { StateCreator } from 'zustand';
import { createAppStore } from '@marriott/mi-store-utils';

type HeaderFieldsState = {
  requestId: string;
  api?: boolean;
};

type HeaderFieldsActions = {
  setRequestId: (requestId: string) => void;
  setApiValue: (apiValue: boolean) => void;
};

const initialState: HeaderFieldsState = {
  requestId: '',
};

export const headerFieldsStore: StateCreator<HeaderFieldsState & HeaderFieldsActions> = set => {
  return {
    ...initialState,

    setRequestId: (requestId: string) => {
      set(prevState => {
        return {
          ...prevState,
          requestId,
        };
      });
    },

    setApiValue: (apiValue: boolean) => {
      set(prevState => {
        return {
          ...prevState,
          api: apiValue,
        };
      });
    },
  };
};

export const useHeaderFieldsStore = createAppStore(headerFieldsStore);
