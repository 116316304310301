import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const { font, color, mediaQuery } = baseVariables;

export const StyledBrandLogo = styled.div`
  font-size: ${font.fontXl};

  &.brand-logo-default {
    color: ${color.neutral40};
  }

  &.brand-logo-SH::before {
    font-size: ${toRem(25)};
  }
  &.brand-logo-GE::before {
    font-size: ${toRem(34)};
  }
  &.brand-logo-AR::before {
    font-size: ${toRem(45)};
  }
  &.brand-logo-AL::before {
    font-size: ${toRem(32)};
  }
  &.brand-logo-AK::before {
    font-size: ${toRem(28)};
  }
  &.brand-logo-CY::before {
    font-size: ${toRem(10)};
  }
  &.brand-logo-DE::before {
    margin-top: ${toRem(6)};
    font-size: ${toRem(46)};
  }
  &.brand-logo-DS::before {
    font-size: ${toRem(6.72)};
  }
  &.brand-logo-EB::before {
    font-size: ${toRem(12)};
  }
  &.brand-logo-EL::before {
    font-size: ${toRem(40)};
  }
  &.brand-logo-FI::before {
    font-size: ${toRem(19)};
  }
  &.brand-logo-FP::before {
    margin-top: ${toRem(4)};
    font-size: ${toRem(33)};
  }
  &.brand-logo-JW::before {
    font-size: ${toRem(27)};
  }
  &.brand-logo-MD::before {
    font-size: ${toRem(10)};
  }
  &.brand-logo-ER::before {
    font-size: ${toRem(33)};
  }
  &.brand-logo-MI::before {
    font-size: ${toRem(28)};
  }
  &.brand-logo-MV::before {
    font-size: ${toRem(39)};
  }
  &.brand-logo-MC::before {
    font-size: ${toRem(33)};
  }
  &.brand-logo-OX::before {
    font-size: ${toRem(27)};
  }
  &.brand-logo-PR::before {
    font-size: ${toRem(35)};
  }
  &.brand-logo-BR::before {
    margin-top: ${toRem(4)};
    font-size: ${toRem(35)};
  }
  &.brand-logo-RI::before {
    font-size: ${toRem(12)};
  }
  &.brand-logo-SI::before {
    font-size: ${toRem(32)};
  }
  &.brand-logo-XR::before {
    font-size: ${toRem(38)};
  }
  &.brand-logo-LC::before {
    font-size: ${toRem(36)};
  }
  &.brand-logo-RZ::before {
    font-size: ${toRem(33)};
  }
  &.brand-logo-TS::before {
    font-size: ${toRem(23)};
  }
  &.brand-logo-TX::before {
    font-size: ${toRem(14)};
  }
  &.brand-logo-WH::before {
    font-size: ${toRem(15)};
  }
  &.brand-logo-WI::before {
    font-size: ${toRem(15)};
  }
  &.brand-logo-XE::before {
    font-size: ${toRem(24)};
  }
  &.brand-logo-reserve::before {
    font-size: ${toRem(7)};
  }
  &.brand-logo-WH-word-mark::before {
    font-size: ${toRem(9)};
  }
  &.brand-logo-BG::before {
    font-size: ${toRem(12)};
  }
  &.brand-logo-RCR::before {
    font-size: ${toRem(12)};
  }
  &.brand-logo-trc::before {
    font-size: ${toRem(16)};
  }
  &.brand-logo-BA::before {
    font-size: ${toRem(35)};
    margin-top: ${toRem(8)};
  }
  &.brand-logo-XF::before {
    font-size: ${toRem(26)};
  }
  &.brand-logo-WV::before {
    font-size: ${toRem(22)};
  }
  &.brand-logo-MG::before {
    font-size: ${toRem(22)};
  }
  &.brand-logo-SN::before {
    font-size: ${toRem(40)};
  }

  @media ${mediaQuery.md} {
    font-size: ${toRem(30)};
  }

  &.brand-logo-large {
    &.brand-logo-SH::before {
      font-size: ${toRem(40)};
    }
    &.brand-logo-GE::before {
      font-size: ${toRem(48)};
    }
    &.brand-logo-AR::before {
      font-size: ${toRem(50)};
    }
    &.brand-logo-AL::before {
      font-size: ${toRem(40)};
    }
    &.brand-logo-AK::before {
      font-size: ${toRem(36)};
    }
    &.brand-logo-CY::before {
      font-size: ${toRem(18)};
    }
    &.brand-logo-DE::before {
      margin-top: 0;
      font-size: ${toRem(60)};
    }
    &.brand-logo-DS::before {
      font-size: ${toRem(12)};
    }
    &.brand-logo-EB::before {
      font-size: ${toRem(20)};
    }
    &.brand-logo-EL::before {
      font-size: ${toRem(54)};
    }
    &.brand-logo-FI::before {
      font-size: ${toRem(30)};
    }
    &.brand-logo-FP::before {
      margin-top: 0;
      font-size: ${toRem(42)};
    }
    &.brand-logo-JW::before {
      font-size: ${toRem(40)};
    }
    &.brand-logo-MD::before {
      font-size: ${toRem(20)};
    }
    &.brand-logo-ER::before {
      font-size: ${toRem(44)};
    }
    &.brand-logo-MI::before {
      font-size: ${toRem(40)};
    }
    &.brand-logo-MV::before {
      font-size: ${toRem(50)};
    }
    &.brand-logo-MC::before {
      font-size: ${toRem(44)};
    }
    &.brand-logo-OX::before {
      font-size: ${toRem(36)};
    }
    &.brand-logo-PR::before {
      font-size: ${toRem(44)};
    }
    &.brand-logo-BR::before {
      margin-top: 0;
      font-size: ${toRem(44)};
    }
    &.brand-logo-RI::before {
      font-size: ${toRem(24)};
    }
    &.brand-logo-SI::before {
      font-size: ${toRem(42)};
    }
    &.brand-logo-XR::before {
      font-size: ${toRem(46)};
    }
    &.brand-logo-LC::before {
      font-size: ${toRem(44)};
    }
    &.brand-logo-RZ::before {
      font-size: ${toRem(42)};
    }
    &.brand-logo-TS::before {
      font-size: ${toRem(32)};
    }
    &.brand-logo-TX::before {
      font-size: ${toRem(22)};
    }
    &.brand-logo-WH::before {
      font-size: ${toRem(18)};
    }
    &.brand-logo-WI::before {
      font-size: ${toRem(38)};
    }
    &.brand-logo-XE::before {
      font-size: ${toRem(30)};
    }
    &.brand-logo-reserve::before {
      font-size: ${toRem(10)};
    }
    &.brand-logo-WH-word-mark::before {
      font-size: ${toRem(12)};
    }
    &.brand-logo-BG::before {
      font-size: ${toRem(22)};
    }
    &.brand-logo-RCR::before {
      font-size: ${toRem(24)};
    }
    &.brand-logo-trc::before {
      font-size: ${toRem(26)};
    }
    &.brand-logo-BA::before {
      font-size: ${toRem(46)};
      margin-top: 0;
    }
    &.brand-logo-XF::before {
      font-size: ${toRem(34)};
    }
    &.brand-logo-WV::before {
      font-size: ${toRem(32)};
    }
    &.brand-logo-MG::before {
      font-size: ${toRem(32)};
    }
    &.brand-logo-SN::before {
      font-size: ${toRem(46)};
    }
  }
`;
