import { FC } from 'react';
import { baseVariables } from '@marriott/mi-ui-library';
import { GroupRate } from '@marriott/mi-groups-graphql';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PropertyCardType, PropertyCard as PropertySearchResultCard } from '../../../molecules';
import {
  getPropertyImages,
  getPropertyItemValue,
  getPropertyLargestRoomArea,
  getPropertyAirportDistanceLabel,
  getImageType,
  showBookNow,
  getReviewsUrl,
  isRfpEligible,
  updateViewType,
  getQueryParams,
  isFutureDate,
  getPropertyBadge,
  getAreaValueAndUnitForLocale,
  getPricingInfo,
} from '../../../utils';
import { useMediaQuery } from '../../../hooks';
import { PropertyCardProps } from './PropertyCard.types';
import { StyledPropertyCard } from './PropertyCard.styles';
import { MiniPropertyCard } from './MiniPropertyCard';
import { useSearchResultsStore, useRfpTrayStore, useLocaleStore } from '../../../store';
import { ViewType } from '../SearchResults.types';

export const PropertyCard: FC<PropertyCardProps> = ({
  variant = 'regular',
  propertyLabels,
  ctas,
  propertyData,
  groupRate,
  bookNowCallback,
}) => {
  const {
    media: { primaryImage },
    basicInformation,
    seoNickname,
    reviews,
    airports,
    meetingRooms,
    matchingSearchFacets,
  } = propertyData;
  const { brand, name: propertyName, openingDate } = basicInformation || {};
  const { addToRfp, viewHotelDetail, selected, bookNow } = ctas;

  const isOpeningSoon = !!(openingDate && isFutureDate(openingDate));
  const badge = getPropertyBadge(basicInformation, propertyLabels, isOpeningSoon);

  const isLargeDesktop = useMediaQuery(baseVariables.mediaQuery.xl);
  const isDesktop = useMediaQuery(baseVariables.mediaQuery.lg);
  const isTablet = useMediaQuery(baseVariables.mediaQuery.md);

  const { locale } = useLocaleStore();
  const { setIsModalOpen, setActivePropertyId, setQuickViewPropertyId, showMapView, propertyMedia, searchQuery } =
    useSearchResultsStore();
  const { isPropertySelected: isSelected, addProperty, removeProperty } = useRfpTrayStore();

  const isMiniVariant = variant === 'mini';
  const showPanelCard = isDesktop && showMapView;
  const isPropertySelected = isSelected(propertyData.id);
  const isQuickGroup = groupRate
    ? showBookNow(
        groupRate,
        searchQuery?.search?.numberOfRooms,
        searchQuery?.search?.numberOfAttendees,
        locale,
        brand?.id
      )
    : false;

  const reviewsAndDistance = {
    rating: `${getPropertyItemValue(reviews?.stars?.count)}`,
    reviewCount: reviews?.numberOfReviews?.count
      ? `${getPropertyItemValue(reviews.numberOfReviews.count)} ${propertyLabels.reviews}`
      : '',
    reviewLink: getReviewsUrl(seoNickname, brand?.id),
    distance: getPropertyAirportDistanceLabel(airports, propertyLabels.toAirport, true),
    reviewTooltipText: propertyLabels.reviewTooltipText,
  };

  const pricingInfo =
    isLargeDesktop && !showMapView && isQuickGroup
      ? getPricingInfo(
          searchQuery?.search?.numberOfRooms as number,
          searchQuery?.search?.numberOfAttendees as number,
          groupRate as GroupRate
        )
      : null;

  const primaryCta = {
    label: isPropertySelected ? selected : addToRfp,
    isSelected: isPropertySelected,
    trackLocation: ctas?.addToRfpTrackLocation,
    onClick: (e: unknown) => handlePropertySelection(e as React.MouseEvent),
  };

  const getPropertyAttributes = () => {
    const attributes = [];

    if (meetingRooms?.totalNumberOfMeetingRooms) {
      attributes.push({
        label: propertyLabels.meetingEventRooms,
        value: `${meetingRooms.totalNumberOfMeetingRooms}`,
      });
    }

    if (basicInformation?.numberOfRooms || basicInformation?.numberOfSuites) {
      attributes.push({
        label: propertyLabels.guestRooms,
        value: getPropertyItemValue(
          parseInt(basicInformation?.numberOfRooms ?? '0', 10) + parseInt(basicInformation?.numberOfSuites ?? '0', 10)
        ),
      });
    }

    const eventSpaceArea = meetingRooms?.totalAreaOfMeetingSpace;
    const eventSpaceAreaUnit = meetingRooms?.areaUnit;

    if (eventSpaceArea) {
      const { area, unit } = getAreaValueAndUnitForLocale(eventSpaceArea, eventSpaceAreaUnit, propertyLabels.spaceUnit);
      attributes.push({
        label: propertyLabels.totalEventSpace,
        value: getPropertyItemValue(area, unit),
      });
    }

    const largestRoomArea = getPropertyLargestRoomArea(meetingRooms?.edges);
    const largestRoomAreaUnit = meetingRooms?.areaUnit;

    if (largestRoomArea) {
      const { area, unit } = getAreaValueAndUnitForLocale(
        +largestRoomArea,
        largestRoomAreaUnit,
        propertyLabels.spaceUnit
      );
      attributes.push({
        label: propertyLabels.largestRoom,
        value: getPropertyItemValue(area, unit),
      });
    }

    const amenities = [
      { code: 'restaurant-on-site', label: propertyLabels.restaurantOnSite },
      { code: 'fitness-center', label: propertyLabels.fitnessCenterOnSite },
      { code: 'business-center', label: propertyLabels.businessCenterOnSite },
      { code: 'free-internet', label: propertyLabels.freeWifi },
    ];

    const isAmenityAvailable = (code: string) => {
      return matchingSearchFacets?.find(item => item.dimension.code === code);
    };

    for (const amenity of amenities) {
      if (attributes.length >= 4) {
        break;
      }
      if (isAmenityAvailable(amenity.code)) {
        attributes.push({
          label: amenity.label,
          value: '',
        });
      }
    }

    return attributes;
  };

  const openQuickViewModal = () => {
    setIsModalOpen(true);
    setQuickViewPropertyId(propertyData.id);
  };

  const handlePropertySelection = (event: React.MouseEvent) => {
    event.preventDefault();
    if (isPropertySelected) {
      removeProperty(propertyData.id);
    } else {
      const imageType = getImageType(ViewType.LIST_VIEW, isDesktop, isTablet, false);
      const brandImage =
        brand?.photos?.length && brand.photos[0]?.content?.length ? brand.photos[0].content[0] : undefined;
      const propertyImage = getPropertyImages(imageType, primaryImage, undefined, brandImage)[0];
      addProperty({ id: propertyData.id, basicInformation, image: propertyImage });
      const queryParams = getQueryParams(window.location.search);
      updateViewType(queryParams['view']);
    }
  };

  const handleInstantBooking = () => {
    bookNowCallback?.(propertyData, groupRate);
  };

  return isMiniVariant ? (
    <MiniPropertyCard
      media={{ primaryImage }}
      propertyName={{ title: propertyName || '', onClick: openQuickViewModal }}
      reviewsAndDistance={reviewsAndDistance}
      {...(isRfpEligible(brand, isOpeningSoon, openingDate as string) ? { footer: primaryCta } : {})}
    />
  ) : (
    <StyledPropertyCard
      className="mt-3"
      onMouseEnter={() => {
        isDesktop && setActivePropertyId(propertyData.id);
      }}
      onMouseLeave={() => {
        isDesktop && setActivePropertyId('');
      }}
    >
      <PropertySearchResultCard
        id={propertyData.id}
        type={showPanelCard ? PropertyCardType.COMPACT : PropertyCardType.REGULAR}
        media={{
          primaryImage,
          photoGallery: propertyMedia?.[propertyData.id]?.media?.photoGallery,
        }}
        badge={badge}
        brand={brand}
        mediaLabels={{
          carouselAriaLabel: propertyLabels.carouselAriaLabel,
          carouselImageAriaLabel: propertyLabels.carouselImageAriaLabel,
          logoAriaLabel: propertyLabels.logoAriaLabel,
        }}
        propertyName={{ title: propertyName || '', onClick: openQuickViewModal }}
        reviewsAndDistance={reviewsAndDistance}
        showDescription={!!isTablet && !showPanelCard}
        description={getPropertyAttributes()}
        viewDetailsCta={{
          label: viewHotelDetail,
          trackDescription: `View Details|${viewHotelDetail} Button|internal|evar106=${propertyData.id},event24`,
          onClick: openQuickViewModal,
        }}
        footer={{
          ...(isRfpEligible(brand, isOpeningSoon, openingDate as string) ? { primaryCta: primaryCta } : {}),
          secondaryCta: isQuickGroup
            ? {
                label: bookNow,
                trackLocation: ctas?.bookNowTrackLocation,
                trackDescription: ctas?.bookNowTrackDescription,
                onClick: handleInstantBooking,
              }
            : undefined,
          pricingInfo: pricingInfo
            ? {
                labels: {
                  guestRoomsFrom: propertyLabels.guestRoomsFrom,
                  perNight: propertyLabels.perNight,
                  eventSpacesFrom: propertyLabels.eventSpacesFrom,
                  perDay: propertyLabels?.perDay,
                },
                data: pricingInfo,
              }
            : undefined,
        }}
      />
    </StyledPropertyCard>
  );
};
