import React, { FC, useEffect, useState } from 'react';
import { InlineMessages, InlineMessagesType } from '@marriott/mi-ui-library';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { useQuery } from '@apollo/client';
import {
  getReslinkEventByIdQuery,
  HotelMediaContent,
  ReslinkEventByIdResponse,
  ReslinkEventByIdInput,
  ReslinkType,
} from '@marriott/mi-groups-graphql';
import { useLocaleStore } from '../../store';
import { isAllowedForLocale, getQueryParams, setCalendarOptions } from '../../utils';
import { ViewType } from '../SearchResults/SearchResults.types';
import { ViewToggle } from '../../molecules';
import { PROPERTY_IMAGE_LIST_CATEGORY_LIMIT } from '../../constants';
import { ReslinkGuestProps } from './ReslinkGuest.types';
import { PropertyMap } from './PropertyMap';
import { EventBanner } from './EventBanner';
import { PropertyList } from './PropertyList';
import { StyledPropertyList } from './ReslinkGuest.styles';

export const ReslinkGuestConfig = {
  emptyLabel: 'ReslinkGuest',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/reslink/reslinkguest`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ReslinkGuest: FC<{ model: ReslinkGuestProps }> = (props: any) => {
  const {
    carouselAriaLabel,
    carouselImageAriaLabel,
    logoAriaLabel,
    bannerImageAltText,
    externalLinkAriaLabel,
    bookBy,
    toAirport,
    reviews,
    reviewTooltipText,
    freeWifi,
    viewHotelDetails,
    priceType,
    dateLabels,
    roomsAndGuests,
    checkAvailability,
    hotelDetails: hotelDetailsLabel,
    overview,
    amenities,
    map,
    mapAriaLabel,
    errors,
  } = props.model;

  const { shortWeekdayNames, longWeekdayNames, shortMonthNames, longMonthNames } = dateLabels;

  const { locale } = useLocaleStore();

  const [eventId, setEventId] = useState('');
  const [reslinkType, setReslinkType] = useState<ReslinkType>(ReslinkType.NONE);
  const [showMapView, setShowMapView] = useState(false);

  useEffect(() => {
    setCalendarOptions({
      shortWeekdayNames,
      longWeekdayNames,
      shortMonthNames,
      longMonthNames,
    });
  }, [shortWeekdayNames, longWeekdayNames, shortMonthNames, longMonthNames]);

  useEffect(() => {
    const { id, key } = getQueryParams(window.location.search);
    setEventId(id);
    setReslinkType(ReslinkType[key as keyof typeof ReslinkType] ?? ReslinkType.NONE);
  }, []);

  const reslinkEventInput: ReslinkEventByIdInput = {
    input: { eventId, reslinkType },
    imageLimitPerCategory: PROPERTY_IMAGE_LIST_CATEGORY_LIMIT,
  };

  const {
    data: eventData,
    loading,
    error,
  } = useQuery<{
    groupsReslinkEventById: ReslinkEventByIdResponse;
  }>(getReslinkEventByIdQuery, {
    variables: reslinkEventInput,
    context: {
      headers: {
        'accept-language': locale,
        'uxl-cache-disable': true,
      },
    },
    fetchPolicy: 'no-cache',
    skip: !eventId,
  });

  const { groupsReslinkEventById } = eventData || {};
  const { eventName, eventDescription, eventCustomLinks, customImageURLs, hotelDetails, httpStatus } =
    groupsReslinkEventById || {};

  const filteredHotelDetails =
    reslinkType === ReslinkType.GRP ? hotelDetails?.filter(property => property.miniHotelInfo.length) : hotelDetails;
  const allPropertiesExpired = reslinkType === ReslinkType.GRP && !filteredHotelDetails?.length;

  const transformMedia = {
    photoGallery: {
      hotelView: {
        edges: customImageURLs?.map((item, index) => ({
          node: {
            alternateDescription: `${bannerImageAltText} ${index + 1}`,
            imageUrls: {
              wideHorizontal: `${item.link}`,
              classicHorizontal: `${item.link}`,
              square: `${item.link}`,
            },
          },
        })),
      },
    },
  };

  const toggleView = () => {
    setShowMapView(!showMapView);
  };

  return (
    <EditableComponent config={ReslinkGuestConfig} {...props}>
      {loading ? (
        <div className="m-spinner-m d-block mx-auto my-4"></div>
      ) : error || (eventData && httpStatus && httpStatus !== 200) ? (
        <div className="py-4">
          <InlineMessages
            type={InlineMessagesType.Error}
            title={errors.apiFailure.title}
            messageList={[errors.apiFailure.description]}
          />
        </div>
      ) : eventData && !groupsReslinkEventById?.isActive ? (
        <div className="py-4">
          <div className="t-title-m mb-2">{errors.disabled.title}</div>
          <div>{errors.disabled.description}</div>
        </div>
      ) : allPropertiesExpired ? (
        <div className="py-4">
          <div className="t-title-m mb-2">{errors?.expiredProperties?.title}</div>
          <div>{errors?.expiredProperties?.description}</div>
        </div>
      ) : (
        <div className="m-container-fullbleed">
          {eventName && !showMapView ? (
            <EventBanner
              eventTitle={eventName}
              eventDescription={eventDescription as string}
              customLinks={eventCustomLinks}
              externalLinkAriaLabel={externalLinkAriaLabel}
              {...(customImageURLs?.length ? { media: transformMedia as HotelMediaContent } : null)}
            />
          ) : null}
          <StyledPropertyList>
            {filteredHotelDetails?.length ? (
              <div className="container">
                {showMapView ? (
                  <PropertyMap
                    reslinkType={reslinkType}
                    propertyLabels={props.model}
                    properties={filteredHotelDetails}
                    toggleView={toggleView}
                  />
                ) : (
                  <>
                    <PropertyList
                      reslinkType={reslinkType}
                      isMapView={showMapView}
                      properties={filteredHotelDetails}
                      propertyLabels={{
                        carouselAriaLabel,
                        carouselImageAriaLabel,
                        logoAriaLabel,
                        bookBy,
                        toAirport,
                        reviews,
                        reviewTooltipText,
                        freeWifi,
                        viewHotelDetails,
                        priceType,
                        dateLabels,
                        roomsAndGuests,
                        checkAvailability,
                        hotelDetails: hotelDetailsLabel,
                        overview,
                        amenities,
                        mapAriaLabel,
                        apiError: errors.apiFailure.title,
                      }}
                    />
                    {isAllowedForLocale(locale) && (
                      <ViewToggle
                        ctaLabel={map}
                        variant={ViewType.MAP_VIEW}
                        ctaAriaLabel={mapAriaLabel}
                        toggleView={toggleView}
                      />
                    )}
                  </>
                )}
              </div>
            ) : null}
          </StyledPropertyList>
        </div>
      )}
    </EditableComponent>
  );
};
